@font-face {
  font-family: "ChicagoFLF";
  src: url("./fonts/ChicagoFLF.ttf") format("truetype");
}

.App {
  text-align: center;
  background-color: black;
  height: 100%;
  min-height: 100vh;
}

.Mui-disabled {
  color: rgb(231, 196, 70, 0.5) !important;
  border: solid 1px rgb(231, 196, 70, 0.5) !important;
}

.MuiPaper-rounded {
  border: solid 1px rgb(231, 196, 70) !important;
  border-radius: 10px !important;
}

.pawnBotAvatar {
  border: none !important;
}
